.container {
  padding: 10px 14px;
  color: rgba(0, 0, 0, 0.85) !important;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #e6f7ff;

  &:hover {
    background-color: #bae7ff;
    border: 1px solid #1890ff;
  }
}
