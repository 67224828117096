.modalDetailsEdit {
  margin-right: 4px;

  :global {
    .ant-modal-body {
      padding: 50px 40px !important;
    }
  }
}

.titleModalDetailsEdit {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #2d2d2d;
}

.headTable {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  font-weight: 700;
  font-family: 'Roboto';
}

.bodyTable {
  display: flex;
  gap: 20px;
  font-style: 13px;
  margin-bottom: 20px;
  font-family: 'Roboto';
}

.col150px {
  width: 150px;
}
.col200px {
  width: 200px;
}

.colFull {
  flex: 1;
}

.colProject {
  width: 150px;
}
.colTitle {
  width: 200px;
}

.colDate {
  display: flex;
  gap: 3px;
  flex-wrap: wrap;
}
