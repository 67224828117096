// global style
@import 'variables.scss';

body {
  font-size: 13px;
  word-break: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// line-clamp
.line-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.max-1-line {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;
}

.max-2-line {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;
}

.line-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* width */

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #d5d5d5;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #aeaeae;
  border-radius: 5px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #d1d1d1;
}

// start custom padding
@mixin p($top, $right, $bottom, $left) {
  padding-top: $top + px !important;
  padding-right: $right + px !important;
  padding-bottom: $bottom + px !important;
  padding-left: $left + px !important;
}

@mixin pl($left) {
  padding-left: $left + px !important;
}

@mixin pr($right) {
  padding-right: $right + px !important;
}

@mixin pb($bottom) {
  padding-bottom: $bottom + px !important;
}

@mixin pt($top) {
  padding-top: $top + px !important;
}

@mixin ptb($top, $bottom) {
  padding-top: $top + px !important;
  padding-bottom: $bottom + px !important;
}

@mixin plr($left, $right) {
  padding-left: $left + px !important;
  padding-right: $right + px !important;
}

@for $i from 0 to 200 {
  .p-#{$i} {
    @include pt($i);
    @include pr($i);
    @include pl($i);
    @include pb($i);
  }

  .pt-#{$i} {
    @include pt($i);
  }

  .pr-#{$i} {
    @include pr($i);
  }

  .pb-#{$i} {
    @include pb($i);
  }

  .pl-#{$i} {
    @include pl($i);
  }

  .plr-#{$i} {
    @include pl($i);
    @include pr($i);
  }

  .ptb-#{$i} {
    @include pt($i);
    @include pb($i);
  }
}

// end custom padding
// start custom margin
@mixin m($top, $right, $bottom, $left) {
  margin-top: $top + px !important;
  margin-right: $right + px !important;
  margin-bottom: $bottom + px !important;
  margin-left: $left + px !important;
}

@mixin mt($top) {
  margin-top: $top + px !important;
}

@mixin ml($left) {
  margin-left: $left + px !important;
}

@mixin mr($right) {
  margin-right: $right + px !important;
}

@mixin mb($bottom) {
  margin-bottom: $bottom + px !important;
}

@mixin mtb($top, $bottom) {
  margin-top: $top + px !important;
  margin-bottom: $bottom + px !important;
}

@mixin mlr($left, $right) {
  margin-left: $left + px !important;
  margin-right: $right + px !important;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

@for $i from 0 to 200 {
  .m-#{$i} {
    @include mt($i);
    @include mr($i);
    @include ml($i);
    @include mb($i);
  }

  .mt-#{$i} {
    @include mt($i);
  }

  .mr-#{$i} {
    @include mr($i);
  }

  .mb-#{$i} {
    @include mb($i);
  }

  .ml-#{$i} {
    @include ml($i);
  }

  .mlr-#{$i} {
    @include ml($i);
    @include mr($i);
  }

  .mtb-#{$i} {
    @include mt($i);
    @include mb($i);
  }
}

// end custom margin
.d-flex {
  display: flex;
}

.flex-direction-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.align-items-start {
  align-items: start;
}

.align-items-baseline {
  align-items: baseline;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-end {
  justify-content: end;
}

.flex-wrap {
  flex-wrap: wrap;
}

.fontsize-13 {
  font-size: 13px;
}

.fontsize-16 {
  font-size: 16px;
}

.font-note {
  font-size: 12px;
}

.fontsize-20 {
  font-size: 20px;
}

.fs-16px {
  font-size: 16px;
}

.heading-20 {
  font-size: 20px;
}

.heading-20-bold {
  font-size: 20px;
  font-weight: bold;
}

.heading-24-bold {
  font-size: 24px;
  font-weight: bold;
}

.heading-30 {
  font-size: 30px;
  font-weight: bold;
}

.heading-16 {
  font-size: 16px;
  font-weight: bold;
}

.heading-14 {
  font-size: 14px !important;
  font-weight: bold;
}

.heading-13 {
  font-size: 13px;
  font-weight: bold;
}

a,
button {
  cursor: pointer;
}

ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.ant-table-thead > tr > th {
  font-size: 13px;
  font-weight: bold !important;
  color: $text !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: normal !important;
}

.ant-btn.ant-btn-primary {
  background: $blue2;
  border-color: $blue2;
}

.ant-btn.btn-outline {
  color: $blue2;
  border-color: $blue2;
}

.ant-btn.btn-outline-timekeeping {
  color: $blue2;
  border-color: $blue2;
  border-radius: 5px;
}

.ant-btn.btn-outline-green {
  color: $green;
  border-color: $green;
}

.ant-btn.btn-outline-timekeeping-green {
  color: $green;
  border-color: $green;
  margin-left: 0;
  background-color: white;
  border-radius: 5px;
  border: 1px solid $green;
}

.ant-btn.btn-outline-timekeeping-green:hover {
  background-color: white;
  color: $green;
}

.ant-btn.btn-outline-red {
  color: $red;
  border-color: $red;
}

.ant-btn.btn-outline-timekeeping-red {
  color: $red;
  border-color: $red;
  margin-left: 0;
  background-color: white;
  border-radius: 5px;
  border: 1px solid $red;
}

.ant-btn.btn-outline-timekeeping-red:hover {
  background-color: white;
  color: $red;
}

.ant-btn.btn-outline-timekeeping-dark-red {
  color: #990000;
  border-color: #990000;
  margin-left: 0;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #900000;
}

.ant-btn.btn-outline-timekeeping-dark-red:hover {
  background-color: white;
  color: #900000;
}

.circle-checkbox {
  .ant-checkbox:after {
    border-radius: 50% !important;
  }

  .ant-checkbox-inner {
    border-radius: 50% !important;
    border-color: $blue2 !important;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: normal !important;
}

.ant-select-arrow {
  color: #757575 !important;
}

body .btnBlue2 {
  background: $blue2;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 3px;
  text-align: center;
  padding: 5px 15px;
  height: 32px !important;

  &:focus,
  &:hover {
    background: $blue2;
    color: white;
  }
}

body .btnWhite2 {
  background: white;
  color: $blue2;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid $blue2;
  border-radius: 3px;
  text-align: center;
  padding: 5px 15px;
  height: 32px !important;
}

input[type='password'] {
  background: transparent;
}

.textPrimary {
  color: $blue2;
}

.textNowrap {
  white-space: nowrap !important;
}

.textPreWrap {
  white-space: pre-wrap;
}

.inputGray .ant-input-group .ant-input,
.inputGray .ant-btn {
  background-color: $background;
}

body .inputGrey {
  background: #f0f0f0 !important;
  border-radius: 3px;
  color: #757575;
  cursor: pointer;

  .ant-select-selector {
    background-color: #f0f0f0 !important;
    height: auto !important;
    border: none !important;
    padding: 1px 11px !important;
  }

  input.ant-input:hover {
    background-color: #f0f0f0 !important;
  }
}

.ant-modal-title {
  font-weight: bold;
}

.ant-modal-content .btnGrey.ant-picker {
  width: 100%;
  background-color: #f0f0f0 !important;
}

body .ant-modal-content .inputGrey .ant-select-selector {
  border: 1px solid #d9d9d9 !important;
  padding: 0 11px !important;
}

body .ant-modal-content .inputGrey.ant-picker {
  border: 1px solid #d9d9d9 !important;
}

.ant-picker-ranges {
  text-align: center;
}

.ant-table table {
  background: $background;
}

.square-checkbox {
  .ant-checkbox-group-item {
    margin-bottom: 20px;
    display: block;
  }

  .ant-checkbox-inner {
    height: 20px;
    width: 20px;
  }
}

.img-cover {
  height: 100%;
  width: 100%;
  display: block;
  object-fit: cover;
}

.img-scaledown {
  height: 100%;
  width: 100%;
  display: block;
  object-fit: scale-down;
}

.w-100 {
  width: 100%;
}

.w-common {
  width: 200px;
}

.cursorPointer {
  cursor: pointer;
}

.btnGrey {
  background: #f0f0f0;
  border-radius: 3px;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0 10px;
  font-size: 13px;
  font-weight: 700;
  color: #757575;
  cursor: pointer;

  .ant-picker-input input::placeholder {
    color: #757575 !important;
  }
}

.ant-picker-suffix {
  font-size: 16px;
  color: $inactive !important;
}

.ant-picker-clear {
  font-size: 16px;
  background-color: $line;
}

.text-green {
  color: #39bb24 !important;
}

.text-white {
  color: white !important;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-bold {
  font-weight: bold !important;
}

.minWidth200 {
  min-width: 200px !important;
}

.ant-table-content {
  overflow-y: hidden;
  overflow-x: auto;
}

.ant-input-affix-wrapper > input.ant-input {
  background: transparent;
}

.row-deactive {
  opacity: 0.5;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 20px;
}

.btn_accept {
  color: $green;
  border-color: $green;
  min-width: 100px;

  &:focus,
  &:hover {
    color: $green;
    border-color: $green;
  }
}

.btn_add {
  background: #2b88d8;
  border-color: #2b88d8;
  color: $white;

  &:focus,
  &:hover {
    background: #2b88d8;
    border-color: #2b88d8;
    color: $white;
  }
}

.btn_cancel {
  width: 100%;
  border-color: #2b88d8;
  color: #2b88d8;

  &:focus,
  &:hover {
    border-color: #2b88d8;
    color: #2b88d8;
  }
}

.btnTable {
  text-align: center;
  border-radius: 3px;
  min-width: 100px;
}

.btn_approval {
  color: $green;
  border-color: $green;
  width: 100%;
  padding: 4px 15px;

  &:focus,
  &:hover {
    color: $green;
    border-color: $green;
  }
}

.btn_reject {
  color: $red;
  border-color: $red;
  margin-right: 10px;
  width: 100%;
  padding: 4px 15px;

  &:focus,
  &:hover {
    color: $red;
    border-color: $red;
  }
}

.footer_modal {
  display: flex;
  justify-content: center;
  padding-right: 10px;
  width: 100%;
}

.w-200px {
  width: 200px;
}

.w-200px-important {
  width: 200px !important;
}

.w-100 {
  width: 100%;
}

.w-100px {
  width: 100px;
}

.w-80px {
  width: 80px !important;
}

.w-50 {
  width: 50%;
}

.footer {
  display: flex;
  justify-content: center;
  padding-right: 10px;
  width: 100%;

  button {
    background-color: $blue2;
    color: white;
    height: 45px;
    min-width: 237px;
    border: none;
    border-radius: 3px;
    margin-top: 20px;
    font-weight: bold;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  &.footerAddVendor {
    justify-content: center;
  }
}

.right {
  text-align: right;
}

.btnWhite {
  background: white;
  border: 1px solid $blue2;
  color: $blue2;
  height: 45px;
  min-width: 237px;
  border-radius: 3px;
  margin-top: 20px;
  font-weight: bold;
}

.btnPrimary2 {
  background: $blue2;
  color: white;
  height: 45px;
  min-width: 237px;
  border-radius: 3px;
  margin-top: 20px;
  font-weight: bold;
}

.btnPrimary {
  color: $white;
  height: 45px;
  min-width: 237px;
  border-radius: 3px;
  margin-top: 20px;
  font-weight: bold;
}

.btnApproved {
  background: white !important;
  border: 1px solid $green !important;
  color: $green;
  height: 45px;
  min-width: 237px;
  border-radius: 3px;
  margin-top: 20px;
  font-weight: bold;

  &:hover,
  &:focus {
    color: $green !important;
  }
}

.btnReject {
  background: white;
  border: 1px solid $red !important;
  color: $red;
  height: 45px;
  min-width: 237px;
  border-radius: 3px;
  margin-top: 20px;
  font-weight: bold;

  &:hover,
  &:focus {
    color: $red !important;
  }
}

.pModal {
  :global {
    .ant-modal-body {
      padding: 50px 100px;
    }
  }
}

.ant-picker-suffix {
  display: inline-flex;
}

.hidden-autocomplete {
  position: absolute;
  pointer-events: none;
  width: 0;
  height: 0;
  border: none !important;
  top: -5000px;
}

.disableCol {
  opacity: 0.5;
}

.ant-modal-header {
  border-bottom-color: transparent;
}

.ant-form-vertical .ant-form-item {
  flex-flow: column;
}

body {
  .react-confirm-alert-overlay {
    z-index: 99999999;
    background-color: rgba(0, 0, 0, 0.45);
  }

  .react-confirm-alert-body {
    width: auto;
    margin: 0 30px;
    border-radius: 2px;

    h1 {
      font-size: 24px;
      margin-bottom: 32px;
    }

    button {
      width: 100%;
      border-radius: 3px;
    }

    // reset styles button for date picker
    .ant-picker-header {
      button {
        width: auto;
      }
    }
  }

  .react-confirm-alert-button-group {
    display: flex;
    justify-content: center;

    button {
      min-width: 100px;

      &:first-child {
        background-color: transparent;
        color: $blue2;
        border: 1px solid $blue2;
      }

      &:last-child {
        background-color: $blue2;
      }
    }
  }
}

.textMobile {
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
  display: block;
  text-align: center;
}

.ant-picker-dropdown .ant-picker-ok {
  display: none;
}

.pointer-events-none {
  pointer-events: none;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.hiddenElement {
  visibility: hidden;
}

@media print {
  .displayNonePrint {
    display: none;
  }
}

.w100px {
  width: 100px;
}

.opacityFaded {
  opacity: 0.5;
}

.border-radius-4 {
  border-radius: 4px;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.drop-over-downward {
  td {
    border-bottom: 2px dashed #1890ff !important;
  }
}

.drop-over-upward {
  td {
    border-top: 2px dashed #1890ff !important;
  }
}

body .inputGreyNoBorder {
  background: #f0f0f0 !important;
  border-radius: 3px !important;
  color: #757575;
  cursor: pointer;
  border: none !important;

  .ant-select-selector {
    background-color: #f0f0f0 !important;
    height: auto !important;
    border: none !important;
    padding: 1px 11px !important;
  }

  input.ant-input:hover {
    background-color: #f0f0f0 !important;
  }
}

.ant-modal-title {
  font-weight: bold;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.border-none {
  border: none;
}

.font-semibold {
  font-weight: 600;
}

.block {
  display: block;
}

.none {
  display: none;
}

.hoverEffect {
  &:hover {
    background-color: #f2f2f2;
  }
}

@media print {
  html,
  body {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }

  .text-print-12 {
    font-size: 12px;
  }

  .text-print-13 {
    font-size: 13px;
  }

  .text-print-14 {
    font-size: 14px;
  }
}

.strong {
  font-weight: bold;
}

.text-error {
  color: #ff4d4f !important;
}
.text-blue {
  color: #2388ff;
}

.text-waring {
  color: #d9962b;
}

.priceDiffPositive {
  color: #39bb24;
}

.priceDiffNegative {
  color: #ff0000;
}

.customTable {
  width: 100%;

  .headingTable {
    text-align: center;
    height: 40px;
    background-color: #dbeeff;
    font-weight: bold;
    font-size: 14px;
    border: 1px solid #8e8e8e;
    border-bottom: 0;
  }

  tbody {
    border: 1px solid #8e8e8e;
  }

  .row {
    display: flex;
    // border-top: 1px solid #8e8e8e;
    &:not(:first-child) {
      border-top: 1px solid #8e8e8e;
    }

    .label,
    .value {
      padding: 10px;
      min-height: 53px;
      flex-shrink: 0;
    }

    .label {
      border-right: 1px solid #8e8e8e;
      display: flex;
      align-items: center;
      width: 150px;
    }

    .value {
      flex: 1;
    }
  }
}

.mb-form-0 {
  &.ant-form-item {
    margin-bottom: 0;
  }
}

.hide-form-error {
  .ant-form-item-explain {
    display: none;
  }
}

.text-black {
  color: rgba(0, 0, 0, 0.85) !important;

  .ant-select-selector {
    color: rgba(0, 0, 0, 0.85) !important;
  }

  .ant-picker-input > input[disabled] {
    color: rgba(0, 0, 0, 0.85) !important;
  }

  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.85) !important;
  }
}

.underLine {
  text-decoration: underline;
  text-underline-offset: 4px;
}

.h-full {
  height: 100%;
}

.border-none {
  border: none !important;
}

.box-shadow-none {
  box-shadow: none !important;
}

// hide supper icon datePicker
.hide-supper-icon {
  .ant-picker-header {
    .ant-picker-header-super-prev-btn,
    .ant-picker-header-super-next-btn {
      display: none;
    }
  }
}

// check-box color-red
.bg-checkbox-red {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: red;
    border-color: red;
  }
}

//
.ant-form-item-explain,
.ant-form-item-extra {
  color: $black;
  font-size: 13px;
  margin-top: 5px;
}

.bg-checkbox-blue {
  &.ant-checkbox-wrapper-checked {
    .ant-checkbox-disabled .ant-checkbox-inner {
      background-color: #1890ff !important;
      border-color: #1890ff !important;

      &::after {
        border-color: #f5f5f5;
      }
    }
  }
}

// START : Color calendar

.rbc-row-segment .rbc-event {
  border-radius: 2px;
}

.rbc-event:has(.bg-gray-calendar) {
  background-color: $bg-gray !important;
}

.rbc-event:has(.default-calendar) {
  border: none !important;
  transition: all 0.2s ease-in-out;
}

// .rbc-event-content:has(.default-calendar),
.rbc-event-content:has(.note-calendar) {
  position: absolute;
  inset: 0;
  border-radius: inherit;
  height: 100%;
}

.rbc-event:has(.canDrop-calendar) {
  border: 2px dashed #2b88d8 !important;
}

.rbc-event:has(.isActive-calendar) {
  background-color: #2b88d8 !important;
}

.rbc-event:has(.note-calendar) {
  background-color: #43b7b8 !important;
  border: none !important;
  transition: all 0.2s ease-in-out;
}

.rbc-event:has(.canDrop-note-calendar) {
  border: 2px dashed #2b88d8 !important;
}

.rbc-event:has(.isActive-note-calendar) {
  background-color: #2b88d8 !important;
}

.colorBlack {
  color: $text;
}

.colorRed {
  color: $red !important;
}

.colorPurple {
  color: $text !important;
  font-style: italic;
  text-decoration: underline;
}

.colorOrange {
  color: $orange6 !important;
}

.bg-gray-calendar-week {
  background-color: $bg-gray !important;
}
.rbc-event:has(.bg-note-calendar) {
  background-color: #43b7b8 !important;
  color: white !important;
}

// END : Color calendar

.fixed-header-form {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 80vh;
}

.hideTableSelection {
  .ant-table-selection-column {
    display: none;
  }
}

/* -------------------------------------------------------------------------- */
/*                                 FLEX UTILS                                 */
/* -------------------------------------------------------------------------- */

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-space-between {
  justify-content: space-between;
}

.width-full {
  width: 100%;
}

.flex-wrap {
  flex-wrap: wrap;
}

/* ---------------------------------- Print --------------------------------- */

@media print {
  .no-print {
    display: none;
  }
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}
.row-dragging .drag-visible {
  visibility: visible;
}

.no-select {
  user-select: none; /* Disable text selection */
}
